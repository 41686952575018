import { Timeline } from 'react-twitter-widgets'


export const ProfileSmaller = () => (
  <Timeline
    dataSource={{ sourceType: "profile", screenName: "reynard_hu" }}
    options={{ 
      theme: "dark",
      // width: "400", 
      // height: "600" 
    }}

  />
);
